function triggerBackgroundChange(target: Element, isIntersecting: boolean) {
  const className = target.getAttribute("intersect");
  const doc = target.getAttribute("intersect-target");
  const el = doc ? document.querySelector(doc) : target;
  if (!el) return;
  if (!className) return;
  if (isIntersecting) el.classList.add(className);
  else el.classList.remove(className);
}

function initializeIntersection() {
  const els = document.querySelectorAll("[intersect]");
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      triggerBackgroundChange(entry.target, entry.isIntersecting);
    });
  });

  for (const el of els) {
    observer.observe(el);
  }
}

function initializeAnimation() {
  const els = document.querySelectorAll("[animate]");
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const animation = entry.target.getAttribute("animate");
        if (!animation) return;
        entry.target.classList.add("animate__animated");
        entry.target.classList.add(animation);
      }
    });
  });

  for (const el of els) {
    observer.observe(el);
  }
}

function initializeDelays() {
  const els = document.querySelectorAll("[delay]");

  for (const el of els) {
    const delay = el.getAttribute("delay") as string;
    const time = parseInt(delay);
    el.classList.add(`animate__delay-${time}s`);
  }
}

initializeAnimation();
initializeDelays();
initializeIntersection();
// initializeSnap();
